import React, { useState, useCallback } from "react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Footer from "../components/Footer";
import Gallery from "react-photo-gallery";
import img1 from '../assets/gallery/1.jpg';
import img2 from '../assets/gallery/2.jpg';
import img3 from '../assets/gallery/3.jpg';
import Carousel, { Modal, ModalGateway } from "react-images";

import "./gallery.css";
import "../components/About/about.css";
const SecondPage = () => 
{ 
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = useCallback((event, { photo, index }) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  }, []);

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };
  const photos = [
    {
      src: img1,
      width: 2,
      height: 1
    },
    {
      src: img2,
      width: 2,
      height: 1
    },
    {
      src: img3,
      width: 3,
      height: 1
    }
  ];
  
  if (typeof window !== 'undefined') {  
    var Tawk_API=Tawk_API||{}, Tawk_LoadStart=new Date();
    (function(){
    var s1=document.createElement("script"),s0=document.getElementsByTagName("script")[0];
    s1.async=true;
    s1.src='https://embed.tawk.to/5ed665f94a7c62581799c5a5/default';
    s1.charset='UTF-8';
    s1.setAttribute('crossorigin','*');
    s0.parentNode.insertBefore(s1,s0);
    })();
    console.log('Chat Working')
  }
  else {
    console.log("Sorry Chat Not Working")
  }
  return (
    <Layout className="gallery">
      <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/twitter-bootstrap/4.5.0/css/bootstrap.min.css" />      
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/tailwindcss/1.4.6/tailwind.min.css" />
          <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.13.0/css/all.min.css" />
          <SEO title="Gallery " description="My Gallery Page" />
          <Container fluid className={"about-container gallery-block"}>
            <Row>
                <Col lg={12} className={"no-pad-col"}>
                    <section className={"c-section"}>
                        <h2 className={"c-section__title pr-0 pl-0"}><span>My Gallery</span></h2>
                        <Gallery photos={photos} onClick={openLightbox} />
                        <ModalGateway>
                          {viewerIsOpen ? (
                            <Modal onClose={closeLightbox}>
                              <Carousel
                                currentIndex={currentImage}
                                views={photos.map(x => ({
                                  ...x,
                                  srcset: x.srcSet,
                                  caption: x.title
                                }))}
                              />
                            </Modal>
                          ) : null}
                        </ModalGateway>
                    </section>
                </Col>
            </Row>
          </Container>    
      <Footer />
    </Layout>
  )
}
export default SecondPage
